import {
    GET_DASHBOARD_DETAILS
  } from "../actions/types";
  const initialState = {
    dashboardData: [],
    loading: true,
  };
  
  export default function docs(state = initialState, action) {
    const { type, payload } = action;
    console.log(payload)
    switch (type) {
      case GET_DASHBOARD_DETAILS:
        return { ...state, loading: false, dashboardData: payload };
      default:
        return state;
    }
  }
  