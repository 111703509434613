// actions.js
export const setPage = (pageNumber) => ({
    type: 'SET_PAGE',
    payload: pageNumber,
  });
  
  // reducers.js
  const initialState = {
    currentPage: 1, // Set initial current page
  };
  
  export default function page(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case 'SET_PAGE':
        return {
          ...state,
          currentPage: action.payload,
        };
        default:
            return state;
        }
      }