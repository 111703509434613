import { GET_BACKGROUND, CLEAR_BACKGROUND_DETAILS } from "../actions/types";

const initialState = {
  imageData: [],
  loading: true,
};

export default function subscribe(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BACKGROUND:
      return { ...state, loading: false, imageData: payload };
    case CLEAR_BACKGROUND_DETAILS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
