import {
    GET_ALL_USERS,
    GET_USER_DETAILS,
    GET_ALL_ACTIVE_USERS,
    GET_USER_REVIEW,
    SEARCH_USER_BY_EMAIL,
} from "../actions/types";
const initialState = {
    userData: [],
    loading: true,
    userDetails: {},
    userAllData: [],
};

export default function user(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_USERS:
            return { ...state, loading: false, userData: payload };
        case SEARCH_USER_BY_EMAIL:
            return { ...state, loading: false, searchUserData: payload };
        case GET_USER_DETAILS:
            return { ...state, loading: false, userDetails: payload };
        //   case GET_USER_REVIEW:
        //     return { ...state, loading: false, userReview: payload };
        default:
            return state;
    }
}
