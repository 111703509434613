import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  STORE_TOKEN,
  ADMIN_GET_DETAILS,
  AUTH_FAIL,
} from "../actions/types";
const initialState = {
  token: localStorage.getItem("token"),
  isLogged: false,
  loading: true,
  setToken: null,
  getAdmin: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  console.log(payload)
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        // ...payload,
        token: payload.token,
        isLogged: true,
        loading: false,
      };
    case LOGIN_FAIL:return {
      ...state,
      token: null,
      isLogged: false,
      loading: false,
    };
    case ADMIN_GET_DETAILS:
      return {
        ...state,
        getAdmin: payload,
        isLogged: true,
        loading: false,
      };
    case AUTH_FAIL:
      return {
        ...state,
        // token: null,
        getAdmin: {},
        isLogged: false,
        loading: false,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        isLogged: false,
        loading: false,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isLogged: false,
        loading: false,
      };
    case STORE_TOKEN:
      return {
        ...state,
        setToken: payload,
      };
    default:
      return state;
  }
}
