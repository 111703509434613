import {
  GET_STICKERS_BY_CATEGORY,
  ADD_STICKER_FAIL,
  ADD_STICKER_SUCCESS,
  CLEAR_STICKER_DATA,
  UPDATE_STICKER_FAIL,
  UPDATE_STICKER_SUCCESS,
  DELETE_STICKER_FAIL,
  DELETE_STICKER_SUCCESS
  } from "../actions/types";
  const initialState = {
    stickerData: [],
    loading: true,
    // userDetails: {},
    // userAllData: [],
  };
  
  export default function stickers(state = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
      case GET_STICKERS_BY_CATEGORY:
        console.log(payload)
        return { ...state, loading: false, stickerData: payload };
      case ADD_STICKER_SUCCESS: 
      return {...state, loading: false, stickerData: stickerData.push(payload)}
      case ADD_STICKER_FAIL: 
      return {...state, loading: false}
      case UPDATE_STICKER_SUCCESS: 
      console.log(payload)
      let updateIndex = stickerData.filter((sticker)=>sticker._id!=payload._id)
      const updatedData = updateIndex.push(payload)
      console.log(updatedData)
      return {...state, loading: false, stickerData: updatedData}
      case UPDATE_STICKER_FAIL: 
      return {...state, loading: false}
      case DELETE_STICKER_SUCCESS: 
      console.log(payload)
      let filteredData= stickerData.filter((sticker)=>sticker._id!==payload)
      return {...state, loading: false, stickerData: filteredData}
      case DELETE_STICKER_FAIL: 
      return {...state, loading: false}
      case CLEAR_STICKER_DATA: 
      return {...state, loading:true, stickerData:[]}
      //   case GET_ALL_ACTIVE_USERS:
      //     return { ...state, loading: false, userAllData: payload };
        // case GET_USER_DETAILS:
        //   return { ...state, loading: false, userDetails: payload };
      //   case GET_USER_REVIEW:
      //     return { ...state, loading: false, userReview: payload };
      default:
        return state;
    }
  }
  