import {

    GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS,
    GET_ALL_USERS_SUBSCRPTION_HISTORY
} from "../actions/types";


const initialState = {
    subsciptionData: [],
    subsciptionDetailsData: {},
    subsciptionHistoryDetailsData: [],
    loading: true,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_USERS_SUBSCRPTION_HISTORY:
            return { ...state, loading: false, subsciptionData: payload };
          case GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS:
            return { ...state, loading: false, subsciptionHistoryDetailsData: payload };
      

        default:
            return state;
    }
}
