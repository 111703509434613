import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_DETAILS,
    GET_VIEW_SUBSCRIPTION,
    CLEAR_SUBSCRIPTION_DETAILS,
  } from "../actions/types";
  
  const initialState = {
    subsciptionData: [],
    subsciptionDetailsData: {},
    // sessionUserList: [],
    loading: true,
  };
  
  export default function subscribe(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_SUBSCRIPTION:
        return { ...state, loading: false, subsciptionData:  payload };
      case GET_SUBSCRIPTION_DETAILS:
        return { ...state, loading: false, subsciptionDetailsData: payload };
      case CLEAR_SUBSCRIPTION_DETAILS:
        return {
          ...state,
          loading: false,
          subsciptionDetailsData: {},
          // sessionUserList: [],
        };
      // case GET_VIEW_SUBSCRIPTION:
      //   return { ...state, loading: false, sessionUserList: payload };
  
      default:
        return state;
    }
  }
  