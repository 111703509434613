import { combineReducers } from "redux";
import authReducers from "./authReducers.js";
import changeState from "./changeState.js";
import alertReducers from "./alertReducers.js";
import userReducers from "./userReducers.js";
import categoryReducers from "./categoryReducers.js";
import stickerReducers from "./stickerReducer.js";
import messagesReducers from "./messagesReducers.js";
import subscriptionReducers from "./subscriptionReducers.js";
import queryReducers from "./queryReducers.js";
import docsReducer from "./docsReducer.js";
import dashboardReducer from "./dashboardReducer.js";
import backgroundReducers from "./backgroundReducers.js";
import userSubscriptionReducers from "./userSubscriptionReducers.js";
import userSubcrptionHistoryReducers from "./userSubcrptionHistoryReducers.js";
import pageReducer from "./pageReducer.js";
import referralReducer from "./referralReducers.js";

export default combineReducers({
    auth: authReducers,
    user: userReducers,
    alert: alertReducers,
    subscribe: subscriptionReducers,
    userSubscribe: userSubscriptionReducers,
    userSubcrptionHistory: userSubcrptionHistoryReducers,
    messages: messagesReducers,
    categories: categoryReducers,
    page: pageReducer,
    stickers: stickerReducers,
    docs: docsReducer,
    dashboard: dashboardReducer,
    query: queryReducers,
    image: backgroundReducers,
    referral: referralReducer,
    sidebarBar: changeState, // don't remove this
});
