import {
  GET_MESSAGES,
  GET_MESSAGES_DETAILS,
  GET_VIEW_SUBSCRIPTION,
  CLEAR_MESSAGES_DETAILS,
  GET_DOCUMENT,
} from "../actions/types";

const initialState = {
  messageData: [],
  documentData: [],
  messageDetailsData: {},
  loading: true,
};

export default function messages(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGES:
      return { ...state, loading: false, messageData: payload };
    case GET_MESSAGES_DETAILS:
      return { ...state, loading: false, messageDetailsData: payload };
    case GET_DOCUMENT:
      return { ...state, loading: false, documentData: payload };
    case CLEAR_MESSAGES_DETAILS:
      return {
        ...state,
        loading: false,
        messageDetailsData: {},
      };

    default:
      return state;
  }
}
