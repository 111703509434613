const initialState = {
  activetab: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "OPEN_TAB":
      return {
        ...state,
        activetab: false,
      };
    case "CLOSE_TAB":
      return {
        ...state,
        activetab: true,
      };

    default:
      return state;
  }
}
