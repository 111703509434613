import {
  ADD_CATE_FAIL,
  ADD_CATE_SUCCESS,
  GET_ALL_CATEGORIES,
  UPDATE_CATE_FAIL,
  UPDATE_CATE_SUCCESS
} from "../actions/types";
const initialState = {
  categoryData: [],
  loading: true,
  // userDetails: {},
  // userAllData: [],
};

export default function categories(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CATEGORIES:
      return { ...state, loading: false, categoryData: payload };
    case ADD_CATE_SUCCESS: 
    return {...state, loading: false, categoryData: categoryData.push(payload)}
    case ADD_CATE_FAIL: 
    return {...state, loading: false}
    case UPDATE_CATE_SUCCESS: 
    console.log(payload)
    let updateIndex = categoryData.filter((cate)=>cate._id!==payload._id)
    const updatedData = updateIndex.push(payload)
    console.log(updatedData)
    return {...state, loading: false, categoryData: updatedData}
    case UPDATE_CATE_FAIL: 
    return {...state, loading: false}

    //   case GET_ALL_ACTIVE_USERS:
    //     return { ...state, loading: false, userAllData: payload };
      // case GET_USER_DETAILS:
      //   return { ...state, loading: false, userDetails: payload };
    //   case GET_USER_REVIEW:
    //     return { ...state, loading: false, userReview: payload };
    default:
      return state;
  }
}
