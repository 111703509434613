export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";
export const LOGOUT = "LOGOUT";
export const ADMIN_GET_DETAILS = "ADMIN_GET_DETAILS";
export const AUTH_FAIL = "AUTH_FAIL";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const SEARCH_USER_BY_EMAIL = "SEARCH_USER_BY_EMAIL";
export const SET_PAGE = "SET_PAGE";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_DETAILS = "GET_MESSAGES_DETAILS";
export const CLEAR_MESSAGES_DETAILS = "CLEAR_MESSAGES_DETAILS";

export const GET_DOCUMENT = "GET_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL";

export const GET_ACTIVE_CATEGORY = "GET_ACTIVE_CATEGORY";

export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_VIEW_SUBSCRIPTION = "GET_VIEW_SUBSCRIPTION";
export const CLEAR_SUBSCRIPTION_DETAILS = "CLEAR_SUBSCRIPTION_DETAILS";

export const CLEAR_USER_QUERY_DETAILS = "CLEAR_USER_QUERY_DETAILS";
export const GET_USER_QUERIES = "GET_USER_QUERIES";

export const CLEAR_BACKGROUND_DETAILS = "CLEAR_BACKGROUND_DETAILS";
export const GET_BACKGROUND = "GET_BACKGROUND";

export const GET_ALL_USERS_SUBSCRPTION = "GET_ALL_USERS_SUBSCRPTION";
export const GET_ALL_USERS_SUBSCRPTION_HISTORY =
    "GET_ALL_USERS_SUBSCRPTION_HISTORY";
export const GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS =
    "GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS";

export const STORE_TOKEN = "STORE_TOKEN";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const ADD_CATE_SUCCESS = "ADD_CATE_SUCCESS";
export const ADD_CATE_FAIL = "ADD_CATE_FAIL";
export const UPDATE_CATE_SUCCESS = "UPDATE_CATE_SUCCESS";
export const UPDATE_CATE_FAIL = "UPDATE_CATE_FAIL";

export const GET_STICKERS_BY_CATEGORY = "GET_STICKERS_BY_CATEGORY";
export const ADD_STICKER_FAIL = "ADD_STICKER_FAIL";
export const ADD_STICKER_SUCCESS = "ADD_STICKER_SUCCESS";
export const UPDATE_STICKER_FAIL = "UPDATE_STICKER_FAIL";
export const UPDATE_STICKER_SUCCESS = "UPDATE_STICKER_SUCCESS";
export const DELETE_STICKER_FAIL = "DELETE_STICKER_FAIL";
export const DELETE_STICKER_SUCCESS = "DELETE_STICKER_SUCCESS";
export const CLEAR_STICKER_DATA = "CLEAR_STICKER_DATA";

export const REFERRAL_REQUEST = "REFERRAL_REQUEST";
