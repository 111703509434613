import {
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAIL,
    GET_DOCUMENT,
    UPDATE_DOCUMENT_FAIL,
    UPDATE_DOCUMENT_SUCCESS
  } from "../actions/types";
  const initialState = {
    docsData: [],
    loading: true,
    // userDetails: {},
    // userAllData: [],
  };
  
  export default function docs(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_DOCUMENT:
        return { ...state, loading: false, docsData: payload };
      case ADD_DOCUMENT_SUCCESS: 
      return {...state, loading: false, docsData: docsData.push(payload)}
      case ADD_DOCUMENT_FAIL: 
      return {...state, loading: false}
      case UPDATE_DOCUMENT_SUCCESS: 
    console.log(payload)
    let updateIndex = docsData.filter((doc)=>doc._id!==payload._id)
    const updatedData = updateIndex.push(payload)
    console.log(updatedData)
    return {...state, loading: false, docsData: updatedData}
    case UPDATE_DOCUMENT_FAIL: 
    return {...state, loading: false}
      //   case GET_ALL_ACTIVE_USERS:
      //     return { ...state, loading: false, userAllData: payload };
        // case GET_USER_DETAILS:
        //   return { ...state, loading: false, userDetails: payload };
      //   case GET_USER_REVIEW:
      //     return { ...state, loading: false, userReview: payload };
      default:
        return state;
    }
  }
  