import { GET_USER_QUERIES, CLEAR_USER_QUERY_DETAILS } from "../actions/types";

const initialState = {
  queryData: [],
  // sessionUserList: [],
  loading: true,
};

export default function query(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_QUERIES:
      return { ...state, loading: false, queryData: payload };
    case CLEAR_USER_QUERY_DETAILS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
